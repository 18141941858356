import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useQuery } from '@tanstack/react-query';
import { QUERY_CONFIG } from '../api/queries';
import { getConfig } from '../api/ConfigApi';

type Props = {
    children: string | JSX.Element | JSX.Element[]
}

function AppPage({ children }: Props) {
    const configQuery = useQuery({
        queryKey: [QUERY_CONFIG],
        queryFn: getConfig,
    });

    // TODO loader dokud nemáme config!
    return (
        <>
            <GoogleOAuthProvider clientId={configQuery.data?.googleClientId}>
                <Header />
                {children}
                <ToastContainer />
            </GoogleOAuthProvider>
        </>
    );
}

export default AppPage;
