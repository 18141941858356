/**
 * Vrátí kořenovou URL serveru na základě aktuálního prostředí (vývojovou či produkční).
 * 
 * @returns kořenová URL serveru
 */
export const getBaseUrl = (): string => {
    if (process.env.PUBLIC_URL) {
        return process.env.PUBLIC_URL;
    }
    return 'http://127.0.0.1:3001';
}

const TOKEN_KEY = "token";

/**
 * Uloží token do local storage prohlížeče.
 * 
 * @param token token
 */
export const storeToken = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token);
}

/**
 * Vrátí token z local storage, pokud tam je.
 * 
 * @returns token nebo null
 */
export const getToken = (): string | null => {
    return localStorage.getItem(TOKEN_KEY);
}

/**
 * Odstraní token z local storage, pokud tam je.
 */
export const deleteToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

/**
 * Převede "technický" řetězec (ve formátu YYYY-MM-DD) na datum.
 *
 * @param str vstupní řetězec ve formátu YYYY-MM-DD
 * @returns datum nebo undefined, pokud se nejedná o platné datum
 */
export const stringToDate = (str?: string | null) => {
    if (!str) {
        return;
    }
    const tmp = str.split("-");
    const date = new Date(parseInt(tmp[0]), parseInt(tmp[1]) - 1, parseInt(tmp[2]));
    return isNaN(date.getTime()) ? undefined : date;
}

/**
 * Převede datum na "uživatelský" řetězec pro zobrazení (např. 1.1.2024).
 *
 * @param date vstupní datum
 * @returns výsledný řetězec
 */
export const dateToHumanString = (date?: Date | null) => {
    if (!date) {
        return;
    }
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

/**
 * Převede datum na "technický" řetězec, vhodný pro ukládání a řazení.
 * 
 * @param date vstupní datum
 * @returns řetězec ve formátu 2024-01-01
 */
export const dateToString = (date: Date) => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}
