import { useEffect } from 'react';
import { useAuth } from '../context/auth';
import { useNavigate } from 'react-router';
import { GoogleLogin } from '@react-oauth/google';
import { googleLogin } from '../api/LoginApi';
import AppPage from './AppPage';
import './LoginPage.scss';

export default function LoginPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.email) {
      navigate('/');
    }
  }, [auth?.email, navigate]);

  if (!auth || !auth.email) {
    return <AppPage>
      <div className='login'>
        <h3>Přihlášení</h3>
        <div style={{ display: "flex", alignSelf: "center" }}>
          <GoogleLogin
            onSuccess={async credentialResponse => {
              const token = await googleLogin(credentialResponse?.credential);
              auth?.setToken(token);
            }}
            onError={() => {
              // TODO vyřešit nějak zobrazení chyby
              console.log('Přihlášení selhalo');
            }}
          />
        </div>
      </div>
    </AppPage>
  }
  return <div>Neplatný stav</div>
}
