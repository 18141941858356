import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import { useQueryClient } from "@tanstack/react-query";
import { apiCall } from "../api/Api";
import { toast } from "react-toastify";
import { restartGame } from "../api/GameApi";
import { QUERY_CHARACTERS, QUERY_MESSAGES } from "../api/queries";

export default function Header() {
    const auth = useAuth();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleLogout = () => {
        if (auth?.logout) {
            auth.logout();
        }
        navigate("/");
    }

    const handleReset = () => {
        confirmAlert({
            title: 'Restart hry',
            message: `Opravdu si přejete restartovat hru? Přijdete o všechny zprávy a postavy zapomenou veškeré konverzace.`,
            buttons: [
                {
                    label: 'Restartovat',
                    onClick: async () => {
                        await apiCall(() => restartGame());
                        toast.success("Hra byla restartována", { theme: "colored" });
                        await queryClient.invalidateQueries({ queryKey: [QUERY_CHARACTERS, QUERY_MESSAGES] });
                        // TODO nevím proč, ale invalidate nestačí, zprávy zůstávají, tak zatím prostě reload
                        window.location.reload();
                    }
                },
                {
                    label: 'Storno',
                }
            ]
        });
    }

    return <Navbar variant='dark' expand="lg">
        <Navbar.Brand href='/'>Murder Mystery</Navbar.Brand>
        {/* <Nav className="nav">
            <>
                <NavItem>
                    <NavLink
                        active
                        href="/neco"
                    >
                        Nekam
                    </NavLink>
                </NavItem>
            </>
        </Nav> */}
        {auth?.email &&
            <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="nav">
                        <NavDropdown align="end" title={auth?.name} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={handleReset}>Restartovat hru</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogout}>Odhlásit se</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </>
        }
    </Navbar>
}