import { Assistant } from "../types/Types";
import { api } from "./Api";

// TODO vyřešit any!
export const getCharacters = async (): Promise<Assistant[]> => {
    return await api.get<any>('/api/game/getCharacters');
}

// TODO vyřešit any!
export const restartGame = async (): Promise<void> => {
    return await api.get<any>('/api/game/restart');
}
