import AppPage from './pages/AppPage';
import { useAuth } from './context/auth';
import { Navigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { getMessages, sendMessage } from './api/ChatApi';
import { getCharacters } from './api/GameApi';
import { useQuery } from '@tanstack/react-query';
import { QUERY_CHARACTERS, QUERY_MESSAGES } from './api/queries';
import { faEllipsis, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Loader from './components/Loader';
import { Assistant, ChatMessages } from './types/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './App.scss';

function App() {
  const auth = useAuth();
  const chatInput = useRef<HTMLTextAreaElement>(null);
  const [selectedCharacter, setSelectedCharacter] = useState<Assistant>();
  const [messages, setMessages] = useState<ChatMessages>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const charactersQuery = useQuery({
    queryKey: [QUERY_CHARACTERS],
    queryFn: getCharacters,
  });

  useQuery({
    queryKey: [QUERY_MESSAGES, selectedCharacter?.id],
    queryFn: async () => {
      if (selectedCharacter?.id != null && !(selectedCharacter.id in messages)) {
        const result = await getMessages(selectedCharacter.id);
        const newMessages = { ...messages };
        newMessages[selectedCharacter.id] = result;
        setMessages(newMessages);
        return messages;
      } else if (selectedCharacter?.id != null) {
        return messages[selectedCharacter.id];
      }
      return [];
    },
  });

  useEffect(() => {
    if (charactersQuery.isFetched && charactersQuery.data && !selectedCharacter?.id) {
      setSelectedCharacter(charactersQuery.data[0]);
    }
  }, [charactersQuery.data, charactersQuery.isFetched, selectedCharacter?.id])

  const saveMessage = async (text: string, characterId: number, from_user: boolean) => {
    const newMessages = { ...messages };
    if (!(characterId in newMessages)) {
      newMessages[characterId] = [];
    }
    newMessages[characterId].push({ text: text, from_user: from_user });
    setMessages(newMessages);
  }

  const doSendMessage = async (text: string, characterId: number) => {
    if (chatInput?.current && selectedCharacter) {
      await saveMessage(chatInput.current.value, characterId, true);
      chatInput.current.value = "";
      setIsLoading(true);
      const response = await sendMessage(text, characterId);
      setIsLoading(false);
      console.log("Odpověď", response); // TODO smazat!
      await saveMessage(response.message, characterId, false);
    }
  }

  const handleKeyDown = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault(); // zabraňuje přidání nové řádky
      if (chatInput?.current?.value && selectedCharacter?.id) {
        doSendMessage(chatInput.current.value, selectedCharacter.id);
      }
    }
  }

  const handleSend = async (e: MouseEvent<HTMLButtonElement> | undefined) => {
    if (chatInput?.current?.value && selectedCharacter?.id) {
      doSendMessage(chatInput.current.value, selectedCharacter.id);
    }
  }

  const switchCharacter = (character: Assistant) => {
    setSelectedCharacter(character);
  }

  if (!auth?.email) {
    return <Navigate to="/login" replace />;
  }

  if (charactersQuery.isFetching) {
    return <Loader
      title={'Načítám data...'}
      icon={faSpinner}
      animation={'fa-spin'}
    />
  }

  return (
    <AppPage>
      <div className='content-wrapper'>
        <div className='content'>
          <h1>Murder mystery</h1>
          <p>Vítejte, uživateli {auth.name}!<br />
            Pro odhlášení využijte nabídku vpravo nahoře.</p>
          <div className='chat-row'>
            <div className='chat-container'>
              <div className='chat-content'>
                {selectedCharacter?.id && selectedCharacter.id in messages ? messages[selectedCharacter.id].map(m => <div className='message'><b>{m.from_user ? "Vy" : selectedCharacter.name}</b>: {m.text}</div>) : null}
                {selectedCharacter?.id && isLoading ? <div className='typing-hint'>{`${selectedCharacter.name} píše`}<FontAwesomeIcon icon={faEllipsis} className={`fa-beat-fade`} style={{ verticalAlign: 'bottom', marginLeft: "5px" }} /></div> : null}
              </div>
              <div className='chat-input-wrapper'>
                <textarea ref={chatInput} placeholder='Sem vložte svůj text...' onKeyDown={handleKeyDown} />
                <Button className='send-button' onClick={handleSend}>Odeslat</Button>
              </div>
            </div>
            <div className='characters'>
              {charactersQuery.data?.map(character =>
                <Button key={character.id} className={`character-button${selectedCharacter?.id === character.id ? ' active' : ''}`} onClick={() => switchCharacter(character)}>{character.name}</Button>)
              }
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </AppPage>
  );
}

export default App;
