import { ChatMessage } from "../types/Types";
import { api } from "./Api";

// TODO vyřešit any!
export const getMessages = async (characterId?: number): Promise<ChatMessage[]> => {
    return await api.get<any>(`/api/chat/getMessages?characterId=${characterId}`);
}

// TODO vyřešit any!
export const sendMessage = async (message: string, characterId?: number): Promise<{ message: string }> => {
    return await api.post<any, any>('/api/chat/sendMessage', JSON.stringify({ message: message, characterId: characterId }));
}
