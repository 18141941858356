import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  title?: String,
  icon: IconDefinition,
  description?: String,
  animation?: String,
}

function Loader(props: Props) {
  return <div className='loader'>
    <h1>{props.title || 'Prosím čekejte...'}</h1>
    <FontAwesomeIcon icon={props.icon} className={`loader-icon mb-3 ` + (props.animation ?? '')} />
    <p>{props?.description}</p>
  </div>
}

export default Loader;
